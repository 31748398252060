import { Box, Grid2, Link } from "@mui/material";
import { useFeature } from "@optimizely/react-sdk";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { ReactElement, useCallback, useMemo } from "react";
import { useSettings } from "../../utils/hooks/use-settings";

type ReleaseNote = {
  id: string;
  content: ReactElement;
};

const historyAtom = atomWithStorage<string[]>("release-notes-seen", []);

export const useReleaseNotes = (): {
  newReleaseNotes: ReleaseNote[];
  seenReleaseNotes: ReleaseNote[];
  handleView: (ids: string[]) => void;
} => {
  const [history, setHistory] = useAtom(historyAtom);
  const [, vars, ready] = useFeature("release_notes");

  const handleView = useCallback(
    (ids: string[]): void => {
      setHistory([...history, ...ids]);
    },
    [history, setHistory],
  );

  const {
    settings: { newUiDialogAcknowledged },
  } = useSettings();

  const isAvailable = useCallback((id: string): boolean => ready && !!vars[id], [ready, vars]);

  const isSeen = useCallback(
    (message: ReleaseNote): boolean => {
      if (["ai", "recipient-activity"].includes(message.id)) {
        return newUiDialogAcknowledged || history.includes(message.id);
      }
      return history.includes(message.id);
    },
    [history, newUiDialogAcknowledged],
  );

  const [seenReleaseNotes, newReleaseNotes] = useMemo(
    () =>
      ready
        ? releaseNotes.reduce(
            (acc, message) =>
              isAvailable(message.id)
                ? isSeen(message)
                  ? [[...acc[0], message], acc[1]]
                  : [acc[0], [...acc[1], message]]
                : acc,
            [[], []] as [ReleaseNote[], ReleaseNote[]],
          )
        : [[], []],
    [isSeen, ready, isAvailable],
  );

  return {
    newReleaseNotes,
    seenReleaseNotes,
    handleView,
  };
};

const releaseNotes: ReleaseNote[] = [
  {
    id: "email-editor",
    content: (
      <>
        <p>
          Introducing our dedicated newsletter flow. We know how much you love making content-rich
          newsletters to send to your employees, so we’ve now streamlined the process of creating
          them! When you’re on the{" "}
          <Link href="/create-moment" target="create-moment">
            Create Moment
          </Link>{" "}
          screen, simply click the ‘Create Newsletter’ button.
        </p>
        <Box display="flex" justifyContent="center" gap={2}>
          <Box>
            <img
              src="https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/4a9d7858-2a00-4a7e-adc2-81f468c422c3"
              alt="screenshot of Create Newsletter button"
              height={200}
            />
          </Box>
          <Box>
            <img
              src="https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/d5d2646e-1b9e-428d-9390-b3bf38c0e463"
              alt="screenshot of Newsletter Editor"
              height={200}
            />
          </Box>
        </Box>
        <p>
          For most of your communications, be sure to use our{" "}
          <Link href="/create-moment">Generate with AI</Link> feature and{" "}
          <Link href="/discover">Discover Library</Link> which hosts 100s of curated, ready to use
          templates. For everything else, create a Moment from scratch and benefit from all of our
          content creation tools.
        </p>
        <Box display="flex" justifyContent="center" gap={2}>
          <Box>
            <img
              src="https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/a8f281a3-4337-4cef-982e-2f998c1ffd29"
              alt="screenshot of From Scratch button"
              height={200}
            />
          </Box>
          <Box>
            <Box>
              <img
                src="https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/da7a7cbd-a59a-4ae0-ae0d-33cc72760513"
                alt="screenshot of Generate with AI"
                height={100}
              />
            </Box>
            <Box>
              <img
                src="https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/b4569415-3a5c-443f-b363-e062f03904d1"
                alt="screenshot of Discovery Library"
                height={92}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
  },
  {
    id: "ai",
    content: (
      <>
        <p>
          We’re excited to unveil our new AI-powered features, designed to boost your creativity and
          productivity and ensure everyone receiving your communications feels a strong sense of
          belonging at work.🤝🏽
        </p>
        <Grid2 container direction="row" justifyContent="center">
          <img
            src={
              "https://help.changeengine.com/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2Fab3e8302-d622-4b3e-ab71-76f5238ec531%2F4bbd4976-8ce2-48ef-83ac-c61bad73d9c0%2FCopy_of_Newsletter_Banners.png?table=block&id=11d88c62-45cc-45d7-8cfc-789a7df840b3&spaceId=ab3e8302-d622-4b3e-ab71-76f5238ec531&width=2000&userId=&cache=v2"
            }
            alt="calendar"
            height={200}
          />
        </Grid2>
      </>
    ),
  },
  {
    id: "recipient-activity",
    content: (
      <>
        <p>
          We’re also pleased to announce our new recipient activity page. You can now uncover which
          Moments your employees have received and their levels of engagement with each.
        </p>
        <Grid2 container direction="row" justifyContent="center">
          <img
            src={
              "https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/91603856-631b-4098-af5c-a88bde23f9d1"
            }
            alt="recipient activity log"
            height={200}
          />
        </Grid2>
      </>
    ),
  },
  {
    id: "segment-manager",
    content: (
      <>
        <p>
          Introducing our new <Link href="/manage/segments/standard">Segment Manager</Link> and ad
          hoc segments. We’ve been hard at working making segmentation more usable for you. As part
          of these new changes, you can now view, edit, favorite and duplicate all your existing
          segments in one place. For more information,{" "}
          <Link href="https://www.loom.com/share/3d8932d3df5f48f9984e61369b48d36d?sid=2c199c5c-585e-4e8b-b7ef-9f61e569f2c4">
            check out our overview video.
          </Link>{" "}
        </p>

        <Grid2 container direction="row" justifyContent="center" gap={2}>
          <img
            src={
              "https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/129d4e5e-6a2d-40aa-85ae-ce23a18f77db"
            }
            alt="segments list"
            height={280}
          />
          <img
            src={
              "https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/24ea3fa1-be87-4b48-a1c3-b2fdd4f10e00"
            }
            alt="segment edit view"
            height={255}
          />
        </Grid2>
      </>
    ),
  },
];
