import { Download } from "@mui/icons-material";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useUnmountEffect } from "@react-hookz/web";
import { isNil } from "lodash-es";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAnalytics } from "../../modules/analytics/analytics";
import { DesignHuddleEditorFrame } from "../../modules/design-huddle/design-huddle-editor-frame";
import { DesignHuddleExportModal } from "../../modules/design-huddle/design-huddle-export-modal";
import { DesignHuddleLoadingModal } from "../../modules/design-huddle/design-huddle-loading-modal";
import { RenameFileModal } from "../../modules/design-huddle/rename-file-modal";
import { Editor } from "../../modules/design-huddle/types";
import { useGetDesignHuddleAccessToken } from "../../modules/design-huddle/use-design-huddle";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { DhTemplateConfig } from "../../modules/multiformat/store/types";
import { useFlag } from "../../server/optimizely";
import { useDialog } from "../../utils/hooks/use-dialog";

type LocationState = {
  customizations: Record<string, unknown>;
  templateConfig: DhTemplateConfig;
  page: number;
};

const EditContentPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { gaEvent } = useAnalytics();

  const getAccessToken = useGetDesignHuddleAccessToken();
  const [projectId, setProjectId] = useState<string>();
  const [error, setError] = useState<Error>();

  const [params] = useSearchParams();
  const { customizations, templateConfig, page } = useMemo(() => {
    const d = params.get("d");
    const decoded = atob(d || "");
    return JSON.parse(decoded) as LocationState;
  }, [params]);

  useEffect(() => {
    if (isNil(templateConfig) || isNil(customizations) || isNil(page)) return;

    void (async function getProjectId() {
      const accessToken = await getAccessToken([]);
      DSHDLib.configure({
        access_token: accessToken,
        visitor: false,
      });
      DSHDLib.createProject(
        {
          template_code: templateConfig.templateCode,
          customizations,
        },
        (err, response) => {
          if (err) {
            setError(err);
            console.error(err);
          } else setProjectId(response.project_id);
        },
      );
    })();
  }, [customizations, getAccessToken, page, templateConfig]);

  const [editor, setEditor] = useState<Editor>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (editor) {
      editor.onInitialized(() => {
        setReady(true);
      });
    }
  }, [editor, projectId, ready]);

  const [multiFormat, , multiFormatLoaded] = useFlag("multi_format");

  const [searchParams] = useSearchParams();
  const contentTypeAppName = searchParams.get("contentTypeAppName") ?? "";

  useEffect(() => {
    if (multiFormatLoaded && !multiFormat) navigate("/404");
  }, [multiFormat, multiFormatLoaded, navigate]);

  useUnmountEffect(() => {
    try {
      editor?.remove();
    } catch (e) {
      console.error(e);
    }
  });

  const exportModal = useDialog();
  const [filename, setFilename] = useState(contentTypeAppName);
  const renameFileModal = useDialog();

  useEffect(() => {
    setFilename(filename);
  }, [filename]);

  const [key, setKey] = useState(0);
  const handleOnSavePressed = (pickedFilename: string): void => {
    setFilename(pickedFilename);
    setKey((prev) => prev + 1);
  };

  if (!multiFormatLoaded) return <CircularProgress />;

  return (
    <Page title={t("Edit Content | ChangeEngine")} sx={{ height: "100%" }}>
      {error && (
        <PageContent>
          <Typography variant="h1">{t("Something went wrong")}</Typography>
          <Typography variant="body1">
            {t("It's not you, it's us. Head back to the dashboard and try again.")}
          </Typography>
        </PageContent>
      )}
      {projectId && (
        <>
          <Stack direction="row" p={2}>
            <Box flexGrow={1} sx={{ ml: 2 }}>
              <Typography variant="h5" color="text.secondary">
                {filename}{" "}
                <Button onClick={() => renameFileModal.handleOpen()}>{t("Edit name")}</Button>
              </Typography>
            </Box>

            <Stack direction="row" gap={2}>
              <Button
                variant="contained"
                size="small"
                startIcon={<Download />}
                onClick={() => {
                  gaEvent("multiformat_button", {
                    action: "click",
                    value: "Export Image in Editor",
                  });
                  exportModal.handleOpen();
                }}
              >
                {t("Export")}
              </Button>
              <DesignHuddleExportModal
                key={key}
                projectId={projectId}
                page={page}
                dialog={exportModal}
                filename={filename}
              />
              <RenameFileModal
                dialog={renameFileModal}
                filename={filename}
                onSavePressed={handleOnSavePressed}
              />
            </Stack>
          </Stack>
          <DesignHuddleEditorFrame
            setEditor={setEditor}
            project_id={projectId}
            state={projectId ? "active" : "disabled"}
            config={{ hide_right_panel: true, page_number: page.toString() }}
          />
        </>
      )}
      <DesignHuddleLoadingModal open={!ready} />
    </Page>
  );
};

export default EditContentPage;
