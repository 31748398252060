import { atomFamily, atomWithDefault } from "jotai/utils";
import { isEqual } from "lodash-es";
import { getTokenAtom } from "../../../server/auth/store";
import { authHeader } from "../../../utils/auth-header";
import { UUID } from "../../../utils/uuid";
import { debugLabel, gptModelAtom } from "../../generate/store";
import { selfDriving } from "../../self-driving/self-driving";

export const previewInitialContextFamily = atomFamily(
  ({ formatId, answers }: { formatId: string; answers: { answer: string }[] }) =>
    debugLabel(
      `initialContextFamily(${formatId}, ${answers[0].answer})`,
      atomWithDefault<Promise<{ chatId: UUID } | null>>(async (get) => {
        const { getToken } = get(getTokenAtom);
        const gptModel = get(gptModelAtom);
        const token = await getToken();
        if (!gptModel || !token) return null;
        const { data, error } = await selfDriving.POST("/multiformat/initial_context", {
          body: {
            description: answers[0].answer,
          },
          params: {
            query: { model: gptModel },
          },
          ...authHeader(token),
        });
        if (error) {
          throw error;
        }
        return data;
      }),
    ),
  isEqual,
);
