import { atomFamily, atomWithDefault, loadable } from "jotai/utils";
import { isEqual } from "lodash-es";
import { Orientation } from "unsplash-js";
import { toSlug } from "../../../utils/create-slug";
import { dhTemplateConfigFamily } from "../../content-previews/content-previews";
import { debugLabel } from "../../generate/store";
import { multiformatAtom } from "./multiformat-atom";
import { previewFamily } from "./preview-family";
import { previewUrlFamily } from "./preview-url-family";
import { Layout } from "./types";

const unsplashResult = (layout: Layout | undefined): number =>
  layout && layout["Unsplash result"] ? layout["Unsplash result"] - 1 : 0;
const orientation = (layout: Layout | undefined): Orientation => {
  return layout &&
    layout.Orientation &&
    ["landscape", "portrait", "squarish"].includes(layout.Orientation) ?
    layout.Orientation as Orientation : "landscape";
}

export const previewsFamily = atomFamily(
  ({ contentType, answers }: { contentType: string; answers: { answer: string }[] }) =>
    debugLabel(
      `previewsFamily(${contentType}, ${answers[0].answer})`,
      atomWithDefault((get) => {
        const templateConfig = get(dhTemplateConfigFamily(contentType));
        const layouts = get(multiformatAtom)?.breakdown[contentType];
        const formatId = toSlug(contentType).replace(/-/g, "_");

        if (!templateConfig || !layouts) return;

        return layouts.map((layout) => ({
          preview: get(
            loadable(
              previewFamily({
                formatId,
                answers,
                imgNum: layout["DH Image Number"]?.toString(),
                templateConfig,
                unsplashResult: unsplashResult(layout),
                orientation: orientation(layout),
              }),
            ),
          ),
          url: get(
            loadable(
              previewUrlFamily({
                formatId,
                answers,
                imgNum: layout["DH Image Number"]?.toString(),
                templateConfig,
                unsplashResult: unsplashResult(layout),
                orientation: orientation(layout),
              }),
            ),
          ),
        }));
      }),
    ),
  isEqual,
);
