import { useAuth0 } from "@auth0/auth0-react";
import { DependencyList, MutableRefObject, useCallback, useEffect } from "react";
import { useToken } from "../../server/auth/use-token";
import { OrgUser } from "../../server/supabase/hooks/types";
import { huddle } from "../huddle/huddle";

export function useDesignHuddle(
  projects: string[],
  ref: MutableRefObject<HTMLDivElement | null>,
  callback: (ref: MutableRefObject<HTMLDivElement | null>) => void,
  deps: DependencyList,
): void {
  const { user } = useAuth0<OrgUser>();
  const getAccessToken = useGetDesignHuddleAccessToken();

  useEffect(() => {
    void (async () => {
      if (!ref.current) return;
      DSHDLib.configure({
        access_token: await getAccessToken(projects),
        visitor: false,
      });
      callback(ref);
    })().catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToken, projects, user, ref.current, ...deps]);
}

export function useGetDesignHuddleAccessToken(): (projects: string[]) => Promise<string> {
  const getToken = useToken();

  return useCallback(
    async (projects) => {
      const response = await huddle.POST("/", {
        body: { projects },
        headers: { Authorization: `Bearer ${await getToken()}` },
      });
      return response.data!.access_token;
    },
    [getToken],
  );
}
