import { config } from "../../config";
import { ImagesService } from "../../server/cloudflare/images-service";
import { serviceURL } from "../../utils/service-url";
import { NameSpaceURL, uuidv5 } from "../../utils/uuid";
import { BrandKitStyle } from "../brand-kit/server/brand-kit-service";
import { BrandInfoSelected, TemplateInfo } from "../brand-kit/types";

export async function copyImage(
  getToken: () => Promise<string | null>,
  src: string,
  id: string,
): Promise<{ success: true; url: string; } | { success: false; }> {
  const token = await getToken();
  if (!token) throw new Error("No token");
  return await new ImagesService().upload(
    new File([await (await fetch(src)).blob()], id),
    uuidv5(NameSpaceURL, `dh:${id}`),
    token,
  );
}

export function imageURL(
  account_id: string,
  id: string,
  cacheBustNumber: number = 1,
): string {
  return `${serviceURL("images")}${account_id}/${uuidv5(
    NameSpaceURL,
    `dh:${id}`,
  )}?v=${cacheBustNumber}`;
}

function optionalMergeTag(
  key: string,
  text?: string,
): { [x: string]: { auto_fit: boolean; text: string } } {
  return text || text === "" ? { [key]: { auto_fit: true, text } } : {};
}

export function brandInfoToCustomizations(
  brandInfo?: BrandInfoSelected,
  templateInfo?: TemplateInfo,
): {
  [key: string]: unknown;
} {
  return {
    classes: {
      ...(brandInfo?.logo
        ? {
            [["icon", "logo", "symbol"].includes(brandInfo.logo.type)
              ? brandInfo.logo.type
              : "logo"]: {
              url: brandInfo.logo.src,
            },
          }
        : {}),
      ...(brandInfo?.color ? { accentcolor: { color: brandInfo.color.hex } } : {}),
      ...(templateInfo?.Photo ? { Photo: { masked_media: { url: templateInfo.Photo } } } : {}),
      ...optionalMergeTag("header", templateInfo?.header),
      ...optionalMergeTag("subheader", templateInfo?.subheader),
      ...optionalMergeTag("tagline", templateInfo?.tagline),
      ...optionalMergeTag("description", templateInfo?.description),
      ...optionalMergeTag("hashtag-1", templateInfo?.["hashtag-1"]),
      ...optionalMergeTag("hashtag-2", templateInfo?.["hashtag-2"]),
      ...optionalMergeTag("footer", templateInfo?.footer),
      ...optionalMergeTag("logo-program-name", templateInfo?.["logo-program-name"]),
      ...optionalMergeTag("logo-tagline", templateInfo?.["logo-tagline"]),
      ...optionalMergeTag("certificate-title", templateInfo?.["certificate-title"]),
      ...optionalMergeTag("certificate-description", templateInfo?.["certificate-description"]),
    },
  };
}

const pages: Record<NonNullable<BrandKitStyle>, number> = {
  bold: 1,
  sophisticated: 2,
  organic: 3,
  formal: 4,
  minimalist: 5,
};

export function getPageNumber(brandInfo?: BrandInfoSelected): number {
  return pages[brandInfo?.branding_type ?? "bold"];
}

const pageAspect: Record<NonNullable<BrandKitStyle>, "landscape" | "portrait" | "squarish"> = {
  bold: "portrait",
  sophisticated: "portrait",
  organic: "portrait",
  formal: "landscape",
  minimalist: "squarish",
};

export function getPageAspect(
  brandInfo?: BrandInfoSelected,
): "landscape" | "portrait" | "squarish" {
  return pageAspect[brandInfo?.branding_type ?? "bold"];
}

export async function getDesignHuddleRender({
  getToken,
  accessToken,
  projectId,
  pageNumber,
  format = "png",
  filename = "file",
}: {
  getToken: () => Promise<string>;
  accessToken: string;
  projectId: string;
  pageNumber?: number;
  format?: string;
  filename?: string;
}): Promise<string> {
  // Get project
  const projectResp = await fetch(
    `https://${config.design_huddle.domain}/api/projects/${projectId}`,
    {
      method: "GET",
      mode: "cors",
      credentials: "omit",
      headers: { authorization: `Bearer ${accessToken}` },
    },
  );

  const { success: projectSuccess, data: projectData } = (await projectResp.json()) as {
    success: boolean;
    data: {
      pages: { page_id: string; page_number: number }[];
    };
  };

  if (!projectSuccess || !projectData) throw new Error("Project Error");

  return getDesignHuddleProjectRender({
    getToken,
    accessToken,
    projectPages: { pages: projectData.pages, projectId },
    newImageId: projectId,
    pageNumber,
    format,
    filename
  });
}

export async function getDesignHuddleProjectRender({
  getToken,
  accessToken,
  projectPages,
  newImageId,
  customizations,
  dimensions,
  pageNumber,
  format = "png",
  filename = "file",
}: {
  getToken: () => Promise<string | null>;
  accessToken: string;
  projectPages: { pages: { page_id: string, page_number: number }[], projectId: string },
  newImageId: string;
  customizations?: {[key: string]: unknown};
  dimensions?: { width: number, height: number };
  pageNumber?: number;
  format?: string;
  filename?: string;
}): Promise<string> {
  const page_id = projectPages.pages.find((p) => p.page_number === pageNumber)?.page_id;

  const body = {
    format,
    page_id,
    filename,
    customizations,
    ...dimensions
  };

  // start export
  const resp = await fetch(
    `https://${config.design_huddle.domain}/api/projects/${projectPages.projectId}/export`,
    {
      method: "POST",
      mode: "cors",
      credentials: "omit",
      headers: { authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(body),
    },
  );

  const { success, data } = (await resp.json()) as {
    success: boolean;
    data: { job_id: string };
  };
  if (!success || !data || !data.job_id) throw new Error("Export Error");

  // poll export
  const download_url = await waitForUrl(accessToken, projectPages.projectId, data.job_id, 1);

  if (format === "pdf") return download_url;

  const image = await copyImage(getToken, download_url, newImageId);
  if (!image.success) throw new Error("Export Error");

  const cacheBust = `?v=${Date.now()}`;
  return `${image.url}${cacheBust}`;
}

async function waitForUrl(
  accessToken: string,
  project_id: string,
  job_id: string,
  attempt: number,
): Promise<string> {
  const resp = await fetch(
    `https://${config.design_huddle.domain}/api/projects/${project_id}/export/jobs/${job_id}`,
    {
      method: "GET",
      mode: "cors",
      credentials: "omit",
      headers: { authorization: `Bearer ${accessToken}` },
    },
  );

  const { success, data } = (await resp.json()) as {
    success: boolean;
    data: { completed: boolean; download_url: string };
  };
  if (!success || !data || (!data.completed && attempt < 10)) {
    await new Promise((resolve) => setTimeout(resolve, attempt * 1000));
    return waitForUrl(accessToken, project_id, job_id, attempt + 1);
  } else if (data.completed) return data.download_url;

  throw new Error("Export Job Timeout");
}
