import { AutoAwesome } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useUpdateEffect } from "@react-hookz/web";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrompts } from "../multiformat/hooks/use-prompts";

export const InspireMeButton: FC<{ onInspire: (prompt: string) => void; format: string }> = ({
  onInspire,
  format,
}) => {
  const { t } = useTranslation();

  const { loading, error, data } = usePrompts();
  const prompts = data?.[format];

  const [inspirePosition, setInspirePosition] = useState(0);

  useUpdateEffect(() => {
    setInspirePosition(0);
  }, [format]);

  const handleInspire = useCallback(() => {
    const prompt = prompts?.[inspirePosition % prompts.length];
    if (!prompt) {
      return;
    }
    onInspire(prompt);
    setInspirePosition(inspirePosition + 1);
  }, [prompts, onInspire, inspirePosition]);

  if (error) {
    return null;
  }

  return (
    <Button
      onClick={handleInspire}
      size="small"
      variant="outlined"
      disabled={loading}
      startIcon={<AutoAwesome />}
    >
      {t("Inspire me")}
    </Button>
  );
};
