import { LinearProgress } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { FC } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useLoadGenerateMomentPage } from "../../../modules/generate/hooks/use-load-generate-moment-page";
import { accountIdAtom, generatedTemplateAtom } from "../../../modules/generate/store";
import { useFlags } from "../../../server/optimizely";
import { useSupabase } from "../../../server/supabase/hooks";
import GenerateMomentPageV1 from "./generate-moment-page-v-1";
import GenerateMomentPageV2 from "./generate-moment-page-v-2";

const GenerateMomentPage: FC<{ recipeId?: string }> = ({ recipeId }) => {
  useLoadGenerateMomentPage(recipeId);

  const {
    flags: { banner_images_enabled },
    flagsLoaded,
  } = useFlags("banner_images_enabled");

  const navigate = useNavigate();

  const setAccountId = useSetAtom(accountIdAtom);
  useSupabase(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ account_id }) => {
      setAccountId(account_id);
    },
    [setAccountId],
  );
  const templateLoader = useAtomValue(generatedTemplateAtom);

  if (templateLoader.state === "hasError") {
    console.error(templateLoader.error);
    toast.error((templateLoader.error as Error).toString());
    navigate("/create-moment");
    return null;
  }

  if (!flagsLoaded) return <LinearProgress />;

  return banner_images_enabled ? (
    <GenerateMomentPageV2 recipeId={recipeId} />
  ) : (
    <GenerateMomentPageV1 recipeId={recipeId} />
  );
};

export default GenerateMomentPage;
