import { TableCell, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PaginatedTable } from "../../generic/components/paginated-table/paginated-table";
import { ConnectionKey } from "../server/supabase-connections-key-service";


export const ConnectionsDataGrid: FC<{
    connectionKeys: ConnectionKey[];
}> = ({ connectionKeys }) => {
    const { t } = useTranslation();
    return (<>
        <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>{t("Connections")}</Typography>
        <PaginatedTable
            config={{
                headers: [
                    t("Key"),
                    t("Name"),
                    t("Number of Connections")
                ],
            }}
            data={connectionKeys}
            renderRow={({ key, display_name, quantity }) => <TableRow>
                <TableCell>
                    {key}
                </TableCell>
                <TableCell>
                    {display_name}
                </TableCell>
                <TableCell>
                    {quantity}
                </TableCell>
            </TableRow>}
        />
    </>
    )
}