import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SupabaseCustomEmail } from "../../../channels/email/server/supabase-custom-email-service";
import { PermissionsFormType } from "../../types";

export const EmailFromDenyListSelect: FC<{
  custom_emails: SupabaseCustomEmail[];
}> = ({ custom_emails }) => {
  const { t } = useTranslation();

  const allowedAddresses = custom_emails.map((s) => ({ title: s.address, id: s.id }));

  const { control } = useFormContext<PermissionsFormType>();

  return (
    <Controller
      name={"email_from_deny_list"}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          multiple={true}
          value={allowedAddresses.filter((s) => field.value?.includes(s.id))}
          options={[{ title: t("Select All"), id: "All" }].concat(allowedAddresses)}
          renderOption={(renderProps, option) => (
            <Box component="li" {...renderProps} key={option.id}>
              {option.title}
            </Box>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} key={option.id} label={option.title} />
            ))
          }
          renderInput={(params) => (
            <TextField
              label={t("Select Email Addresses the user should NOT be able to send from.")}
              variant="standard"
              {...params}
              sx={{ mt: 2 }}
              helperText={error?.message}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
                "data-testid": "email_from-denylist-select",
              }}
            />
          )}
          getOptionLabel={(option) => option.title}
          onChange={(_, v) => {
            const value = v.map((s) => s.id);
            field.onChange(value.includes("All") ? allowedAddresses.map((p) => p.id) : value);
          }}
        />
      )}
    />
  );
};
