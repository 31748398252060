import { atomFamily, atomWithDefault } from "jotai/utils";
import { isEqual } from "lodash-es";
import { Orientation } from "unsplash-js";
import { debugLabel } from "../../generate/store";
import { previewFamily } from "./preview-family";
import { DhTemplateConfig } from "./types";

export const previewUrlFamily = atomFamily(
  ({
    formatId,
    answers,
    imgNum,
    templateConfig: templateConfig,
    unsplashResult,
    orientation,
  }: {
    formatId: string;
    answers: { answer: string }[];
    imgNum?: string;
    templateConfig: DhTemplateConfig;
    unsplashResult: number;
    orientation: Orientation,
  }) =>
    debugLabel(
      `previewFamily(${imgNum})`,
      atomWithDefault(async (get) => {
        if (!imgNum) return null;
        const preview = await get(previewFamily({
          formatId, answers, imgNum, templateConfig, unsplashResult, orientation
        }));
        if (!preview) return null;
        return await new Promise<string>((resolve, reject) => {
          DSHDLib.getVariableTemplatePreviewURL(preview, (err, url) => {
            if (err) {
              reject(err);
            } else {
              resolve(url);
            }
          });
        });
      }),
    ),
  isEqual,
);
