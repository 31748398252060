import { yupResolver } from "@hookform/resolvers/yup";
import { AutoFixHigh, Close } from "@mui/icons-material";
import { Box, Button, Grid2, IconButton, Stack, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import ThemeProvider from "../../styles/theme/theme-provider";
import yup from "../../utils/yup";
import { useAnalytics } from "../analytics/analytics";
import { CreateContentRequest } from "../multiformat/store/types";
import { ContentDescriptionTextField } from "./content-description-textfield";
import { ContentToneSelect } from "./content-tone-select";
import { ContentTypeSelect } from "./content-type-select";
import { useBrandToneOptions } from "./hooks/use-brand-tone-options";
import { InspireMeButton } from "./inspire-me-button";

export const CreateContentCard: FC<{ onClose?: () => void }> = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const brandToneOptions = useBrandToneOptions();
  const { gaEvent } = useAnalytics();

  const schema = useMemo(() => {
    return yup.object({
      q: yup.string().required(t("Please describe the content you wish to create.")),
      tone: yup
        .string()
        .oneOf(brandToneOptions.data?.map((option) => option.value) ?? [])
        .optional(),
    });
  }, [brandToneOptions, t]);

  const methods = useForm<CreateContentRequest>({
    defaultValues: {
      q: searchParams.get("q") ?? searchParams.get("answer") ?? "",
      format: searchParams.get("format") ?? "Communication",
    },
    resolver: yupResolver(schema),
  });

  const format = methods.watch("format");

  const handleSubmit = useCallback(
    (request: CreateContentRequest) => {
      gaEvent("content_type_generation", { action: "submit", value: format });

      if (format === "Communication") {
        const params = createSearchParams({ q: request.q, brand_tone: request.tone ?? "" });
        navigate({ pathname: "/generate-moment", search: params.toString() });
        return;
      }

      const params = createSearchParams({ answer: request.q, format: request.format });
      navigate({ pathname: "/create-content/preview", search: params.toString() });
    },
    [format, navigate, gaEvent],
  );

  const handleInspire = useCallback(
    (prompt: string) => {
      gaEvent("inspire_me", { action: "click", value: format });
      methods.setValue("q", prompt);
    },
    [format, gaEvent, methods],
  );

  return (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Box
          sx={(theme) => ({
            backgroundColor: "neutral.900",
            backgroundPosition: "right bottom, left top, right 40px top 40px",
            backgroundRepeat: "no-repeat",
            backgroundImage:
              'url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/b755211d-9387-4973-832c-3aa1e3c3a414"), \
                url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/037dff25-8e4d-40e0-be06-641ff8a4569c"), \
                url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/a5807452-5f9f-4b4e-9b19-f37e0d4d8c62")',
            p: 5,

            [theme.breakpoints.down("md")]: {
              pr: 3,
              pb: 3,
              backgroundImage:
                'url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/b755211d-9387-4973-832c-3aa1e3c3a414"), \
                url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/037dff25-8e4d-40e0-be06-641ff8a4569c")',
            },
          })}
        >
          <ThemeProvider mode="dark">
            {onClose && (
              <IconButton
                onClick={onClose}
                sx={(theme) => ({
                  position: "relative",
                  float: "right",
                  top: theme.spacing(-3),
                  right: theme.spacing(-1),
                  [theme.breakpoints.down("md")]: {
                    right: theme.spacing(2),
                  },
                })}
              >
                <Close />
              </IconButton>
            )}
            <Typography variant="h5" color="text.primary">
              {t("Create Content Instantly with AI!")}
            </Typography>
            <Typography variant="body2" mt={1} color="text.primary">
              {t("Let our powerful AI generate complete content for you in seconds.")}
            </Typography>
            <Grid2 container spacing={4} mt={2}>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Stack gap={2}>
                  <ContentTypeSelect />
                  {format === "Communication" && <ContentToneSelect />}
                </Stack>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <ContentDescriptionTextField />
                <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
                  <InspireMeButton onInspire={handleInspire} format={format} />
                </Stack>
              </Grid2>
            </Grid2>
          </ThemeProvider>
          <Stack direction="row" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={(theme) => ({
                mt: 2,
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
              startIcon={<AutoFixHigh />}
            >
              {t("Generate Content")}
            </Button>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  );
};
