import { ErrorOutlineOutlined } from "@mui/icons-material";
import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreateContentCard } from "../../modules/create-content-card/create-content-card";
import { Page } from "../../modules/layout/components/page";
import { useMultiformat } from "../../modules/multiformat/hooks/use-multi-format";
import { useFlag } from "../../server/optimizely";
import { CreateFromScratchCard } from "../moments/cards/create-from-scratch-card";
import { DiscoverCard } from "../moments/cards/discover-card";
import { NewsletterCard } from "../moments/cards/newsletter-card";

const CreateContentPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [multiFormat, , multiFormatLoaded] = useFlag("multi_format");

  useEffect(() => {
    if (multiFormatLoaded && !multiFormat) navigate("/create-moment");
  }, [multiFormat, multiFormatLoaded, navigate]);

  const { error } = useMultiformat();

  if (!multiFormatLoaded) return <CircularProgress />;

  return (
    <Page title={t("Create Content | ChangeEngine")} sx={{ maxWidth: "xl" }}>
      {!error && <CreateContentCard />}
      {error && (
        <Card>
          <CardContent>
            <Box display="flex" gap={2} alignItems="center" p={2}>
              <ErrorOutlineOutlined />
              <Typography>
                {t(
                  "There was a problem trying to load our AI content generator. Please refresh your browser to try again, or use one of the options below.",
                )}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
      <Box
        gap={4}
        m={5}
        display="grid"
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
        })}
      >
        <CreateFromScratchCard />
        <DiscoverCard />
        <NewsletterCard />
      </Box>
    </Page>
  );
};

export default CreateContentPage;
