import { atomFamily, atomWithDefault } from "jotai/utils";
import { isEqual } from "lodash-es";
import { Orientation } from "unsplash-js";
import { brandingAtom } from "../../brand-kit/store";
import { TemplateInfo } from "../../brand-kit/types";
import { brandInfoToCustomizations } from "../../design-huddle/utils";
import { debugLabel, unsplashSearchFamily } from "../../generate/store";
import { layoutConfigurationFamily } from "./layout-configuration-family";
import { DhTemplateConfig } from "./types";

export const previewFamily = atomFamily(
  ({
    formatId,
    answers,
    imgNum,
    templateConfig: templateConfig,
    unsplashResult,
    orientation,
  }: {
    formatId: string;
    answers: { answer: string }[];
    imgNum?: string;
    templateConfig: DhTemplateConfig;
    unsplashResult: number;
    orientation: Orientation;
  }) =>
    debugLabel(
      `previewFamily(${imgNum})`,
      atomWithDefault(async (get) => {
        if (!imgNum) return null;
        const layoutConfig = await get(layoutConfigurationFamily({ formatId, answers, imgNum }));
        if (!layoutConfig) return null;

        const branding = get(brandingAtom);

        const unsplashResults = layoutConfig.response.search
          ? await get(
              unsplashSearchFamily({
                query: layoutConfig.response.search,
                orientation,
              }),
            )
          : undefined;

        const Photo = unsplashResults?.[unsplashResult]?.urls.regular ?? layoutConfig.response.url;

        const info: TemplateInfo = {
          template_code: templateConfig.templateCode,
          page_number: parseInt(imgNum),
          stableDiffusionImage: false,
          header: layoutConfig.response.header ?? "",
          subheader: layoutConfig.response.subheader ?? "",
          tagline: layoutConfig.response.tagline ?? "",
          description: layoutConfig.response.description ?? "",
          "hashtag-1": layoutConfig.response["hashtag-1"] ?? "",
          "hashtag-2": layoutConfig.response["hashtag-2"] ?? "",
          footer: layoutConfig.response.footer ?? "",
          "logo-program-name": layoutConfig.response["logo-program-name"] ?? "",
          "logo-tagline": layoutConfig.response["logo-tagline"] ?? "",
          "certificate-title": layoutConfig.response["certificate-title"] ?? "",
          "certificate-description": layoutConfig.response["certificate-description"] ?? "",
          Photo,
        };

        const customizations = brandInfoToCustomizations(branding, info);

        return {
          template_code: info.template_code,
          customizations,
          page_number: info.page_number,
          ...templateConfig.dimensions,
        };
      }),
    ),
  isEqual,
);
