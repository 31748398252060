import { atom } from "jotai";
import { multiformatAtom } from "./multiformat-atom";
import { ContentTypeOption } from "./types";

export const contentTypeOptionsAtom = atom<ContentTypeOption[] | undefined>((get) => {
  const multiformat = get(multiformatAtom);
  return multiformat?.["content-types"]
    .map((contentType) => ({
      label: contentType["CE App Name"] ?? contentType["Content Type"],
      value: contentType["Content Type"],
      order: contentType["Order"],
    }))
    .sort(
      (a: ContentTypeOption, b: ContentTypeOption) =>
        (a.order ? a.order : 0) - (b.order ? b.order : 0),
    );
});
