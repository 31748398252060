/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { addBreadcrumb } from "@sentry/react";
import {
  PostgrestClient,
  PostgrestError,
  PostgrestFilterBuilder,
  PostgrestResponse,
} from "@supabase/postgrest-js";
import { GenericSchema } from "@supabase/postgrest-js/dist/cjs/types";
import { UUID } from "../../utils/uuid";
import { Database } from "./types/database-definitions";

type FilterOperator =
  | "eq"
  | "neq"
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "like"
  | "ilike"
  | "is"
  | "in"
  | "cs"
  | "cd"
  | "sl"
  | "sr"
  | "nxl"
  | "nxr"
  | "adj"
  | "ov"
  | "fts"
  | "plfts"
  | "phfts"
  | "wfts"
  | "not.eq"
  | "not.neq"
  | "not.gt"
  | "not.gte"
  | "not.lt"
  | "not.lte"
  | "not.like"
  | "not.ilike"
  | "not.is"
  | "not.in"
  | "not.cs"
  | "not.cd"
  | "not.sl"
  | "not.sr"
  | "not.nxl"
  | "not.nxr"
  | "not.adj"
  | "not.ov"
  | "not.fts"
  | "not.plfts"
  | "not.phfts"
  | "not.wfts";

export type Filter<T extends Record<string, unknown>> = {
  key: string & keyof T;
  operator: FilterOperator;
  value: unknown;
};

export type GetAllOptions<T extends Record<string, unknown>> = {
  order: { column: string & keyof T; dir?: "asc" | "desc" }[];
  is?: { key: string & keyof T; value: boolean | null }[];
  filter?: Filter<T>[];
  limit?: number;
  pageSize?: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetResult<A extends PostgrestFilterBuilder<any, any, any>> =
  A extends PostgrestFilterBuilder<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    infer B
  >
    ? B
    : never;

// We assume/hope PostgREST stabally detects reference cardinality
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NotArray<T extends Record<string, any>, S extends string> = {
  [k in keyof T]: k extends S
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      NotArray<Exclude<T[k], any[]> extends undefined ? T[k][0] : Exclude<T[k], any[]>, S>
    : T[k];
};

export class BaseSupabaseService<
  SchemaName extends keyof Database = "public" extends keyof Database ? "public" : keyof Database,
> {
  client: PostgrestClient<Database, SchemaName>;

  constructor(client: PostgrestClient<Database, SchemaName>) {
    this.client = client;
  }

  protected async getAllForRPC<
    RPC extends string &
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      keyof (Database[SchemaName] extends GenericSchema ? Database[SchemaName] : any)["Functions"],
  >(
    rpc: RPC,
    args: (Database[SchemaName] extends GenericSchema
      ? Database[SchemaName]
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any)["Functions"][RPC]["Args"],
  ) {
    const pageSize = 1000;
    const allResults = [];
    let start = 0;

    while (true) {
      const resp = this.log_errors(
        await this.client
          .rpc(rpc, args, { get: true, count: "exact" })
          .select()
          .range(start, start + pageSize - 1),
      );

      if (resp.error) return resp;

      allResults.push(...resp.data);

      start += pageSize;

      if (resp.data.length < pageSize) {
        return { ...resp, data: allResults };
      }
    }
  }

  protected makePageSize(pageSize?: number, limit?: number): number {
    const size = pageSize ?? 1000;
    return limit ? Math.min(size, limit) : size;
  }

  protected async getAllFromSelection<R extends Record<string, unknown>>(
    account_id: UUID | null,
    select: PostgrestFilterBuilder<Database[SchemaName], Record<string, unknown>, R[]>,
    options: GetAllOptions<R>,
  ): Promise<PostgrestResponse<R>> {
    const { order, is, limit, filter } = options ?? {};

    const pageSize = this.makePageSize(options?.pageSize, limit);

    if (account_id) select = select.eq("account_id", account_id);

    order.map(
      (column) =>
        (select = select.order(column.column, {
          ascending: [undefined, "asc"].includes(column.dir),
        })),
    );

    if (is) is.map(({ key, value }) => (select = select.is(key, value)));
    if (limit) select = select.limit(limit);

    if (filter)
      filter.map(({ key, operator, value }) => (select = select.filter(key, operator, value)));

    let allResults: R[] = [];
    let start = 0;
    let r;
    do {
      r = this.log_errors(await select.range(start, start + pageSize - 1));
      start += pageSize;
      if (r.error || !r.data) return r;
      allResults = allResults.concat(r.data);
    } while (r.data.length === pageSize && (limit === undefined || r.data.length < limit));
    return { ...r, data: limit === undefined ? allResults : allResults.slice(0, limit) };
  }

  protected log_errors<T>(r: T & { error?: PostgrestError | null }): T {
    if (r.error)
      addBreadcrumb({
        message: r.error.message,
        level: "error",
        data: { details: r.error.details },
      });
    return r;
  }
}

export abstract class SupabaseService<
  T extends keyof Database["public"]["Tables"],
> extends BaseSupabaseService {
  abstract table: T;

  async insert(props: (Database["public"]["Tables"][T] & { [k: string]: never })["Insert"]) {
    const t = await this.client
      .from(this.table)
      .insert(props)
      .select<"*", Database["public"]["Tables"][T]["Row"]>()
      .single();
    return this.log_errors(t);
  }

  async delete(id: (Database["public"]["Tables"][T]["Row"] & { [k: string]: never })["id"]) {
    const { error } = this.log_errors(await this.client.from(this.table).delete().match({ id }));
    if (error) return error;
  }

  async get(id: (Database["public"]["Tables"][T]["Row"] & { [k: string]: never })["id"]) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .select<"*", Database["public"]["Tables"][T]["Row"]>()
        .eq("id", id)
        .single(),
    );
  }

  async maybeGet(id?: (Database["public"]["Tables"][T]["Row"] & { [k: string]: never })["id"]) {
    const query = this.client
      .from(this.table)
      .select<"*", Database["public"]["Tables"][T]["Row"]>();
    return this.log_errors(await (id ? query.eq("id", id) : query).maybeSingle());
  }

  async upsert(
    record: (Database["public"]["Tables"][T] & { [k: string]: never })["Insert"],
    options?: { onConflict: string },
  ) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .upsert(record, options)
        .select<"*", Database["public"]["Tables"][T]["Row"]>()
        .single(),
    );
  }

  async update(
    id: (Database["public"]["Tables"][T]["Row"] & { [k: string]: never })["id"],
    updateProps: (Database["public"]["Tables"][T] & { [k: string]: never })["Update"],
  ) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .update(updateProps)
        .match({ id })
        .select<"*", Database["public"]["Tables"][T]["Row"]>()
        .single(),
    );
  }

  getAllQuery() {
    return this.client.from(this.table).select<"*", Database["public"]["Tables"][T]["Row"]>("*");
  }

  async getAll(
    account_id: UUID | null,
    options: GetAllOptions<
      NotArray<
        GetResult<ReturnType<this["getAllQuery"]>>[0],
        "program" | "icon" | "schedule" | "segment"
      >
    >,
  ): Promise<
    PostgrestResponse<
      NotArray<
        GetResult<ReturnType<this["getAllQuery"]>>[0],
        "program" | "favorite_segment" | "icon" | "schedule" | "segment"
      >
    >
  > {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    return (await this.getAllFromSelection(account_id, this.getAllQuery(), options as any)) as any;
  }

  async count() {
    const { count, ...rest } = await this.client
      .from(this.table)
      .select("id", { count: "exact", head: true });
    if (rest.error || !count) return { ...rest, count: null };
    return { ...rest, data: count };
  }
}

export abstract class SupabaseServiceView<
  T extends keyof Database["public"]["Views"],
> extends BaseSupabaseService {
  abstract table: T;

  async insert(props: (Database["public"]["Views"][T] & { [k: string]: never })["Insert"]) {
    const t = await this.client
      .from(this.table)
      .insert(props)
      .select<"*", Database["public"]["Views"][T]["Row"]>()
      .single();
    return this.log_errors(t);
  }

  async delete(id: (Database["public"]["Views"][T]["Row"] & { [k: string]: never })["id"]) {
    const { error } = this.log_errors(await this.client.from(this.table).delete().match({ id }));
    if (error) return error;
  }

  async get(id: (Database["public"]["Views"][T]["Row"] & { [k: string]: never })["id"]) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .select<"*", Database["public"]["Views"][T]["Row"]>()
        .eq("id", id)
        .single(),
    );
  }

  async maybeGet(id?: (Database["public"]["Views"][T]["Row"] & { [k: string]: never })["id"]) {
    const query = this.client.from(this.table).select<"*", Database["public"]["Views"][T]["Row"]>();
    return this.log_errors(await (id ? query.eq("id", id) : query).maybeSingle());
  }

  async upsert(
    record: (Database["public"]["Views"][T] & { [k: string]: never })["Insert"],
    options?: { onConflict: string },
  ) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .upsert(record, options)
        .select<"*", Database["public"]["Views"][T]["Row"]>()
        .single(),
    );
  }

  async update(
    id: (Database["public"]["Views"][T]["Row"] & { [k: string]: never })["id"],
    updateProps: (Database["public"]["Views"][T] & { [k: string]: never })["Update"],
  ) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .update(updateProps)
        .match({ id })
        .select<"*", Database["public"]["Views"][T]["Row"]>()
        .single(),
    );
  }

  getAllQuery() {
    return this.client.from(this.table).select<"*", Database["public"]["Views"][T]["Row"]>("*");
  }

  async getAll(
    account_id: UUID | null,
    options: GetAllOptions<
      NotArray<
        GetResult<ReturnType<this["getAllQuery"]>>[0],
        "program" | "icon" | "schedule" | "segment"
      >
    >,
  ): Promise<
    PostgrestResponse<
      NotArray<
        GetResult<ReturnType<this["getAllQuery"]>>[0],
        "program" | "icon" | "schedule" | "segment"
      >
    >
  > {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    return (await this.getAllFromSelection(account_id, this.getAllQuery(), options as any)) as any;
  }

  async count() {
    const { count, ...rest } = await this.client
      .from(this.table)
      .select("id", { count: "exact", head: true });
    if (rest.error || !count) return { ...rest, count: null };
    return { ...rest, data: count };
  }
}

export function noNullFieldsSingle<T>(d: T | undefined): d is
  | {
      [K in keyof T]: Exclude<T[K], null>;
    }
  | undefined {
  return true;
}

export function noNullFields<T>(d: T[] | undefined): d is
  | {
      [K in keyof T]: Exclude<T[K], null>;
    }[]
  | undefined {
  return true;
}

export function assertNoNullFields<T>(d: T[] | undefined | null): asserts d is
  | {
      [K in keyof T]: Exclude<T[K], null>;
    }[]
  | undefined 
  | null {
  if (!noNullFields(d || undefined)) throw new Error("Bad data");
}